import dayjs from 'dayjs'
const getDefaultState = () => { // state
    const initial_range_date = [
        // 去年7月1日
        dayjs().subtract(1, 'year').month(6).date(1).format("YYYY-MM-DD"),
        // 今年6月30日
        dayjs().month(5).date(30).format("YYYY-MM-DD")
    ]

    return {
        filter_: sessionStorage.getItem('filter_transfer_sell') 
                // 如果有就使用
                ? JSON.parse(sessionStorage.getItem('filter_transfer_sell')) 
                // 如果沒有就用預設
                : {
                    goal_area_weight: 4.5,
                    range_date_idx: initial_range_date
                }
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setFilter(state, payload){
            state.filter_ = payload
            // Save to sessionStorage
            sessionStorage.setItem('filter_transfer_sell', JSON.stringify(state.filter_))
        },
    },
    
    actions: { 

    },

}