// import axios from 'axios'
import service from '@/api/service'
// const Instance = service.create({
//     baseURL: '/api/daily_report/'
// })
  
const url = '/outside-input'
export const getOutsideInput = (params) => service.get(`${url}/`, {params})

export const createOutsideInput = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
})

export const getSingleOutsideInput = (id) => service.get(`${url}/${id}/`)
export const updateOutsideInput = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
})
export const deleteOutsideInput = (id) => service.delete(`${url}/${id}/`)