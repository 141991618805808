import service from '@/api/service'
const url = '/food/upload/excel/sweet'

// 上傳甜度Excel
export const uploadSweetExcel = (data) => service.post(`${url}/`, data, {
    headers: {
    'Content-Type': 'multipart/form-data',
    },
})

// 進行匯入
export const importSweetExcel = (data, config) => service.post(`${url}/import/`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
        ...config
    })

// 下拉查看加工品檢驗是否已經匯入
export const searchImportSweetExcel = (data, config) => service.post(`${url}/import/search/`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
        ...config
    })


