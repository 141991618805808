// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class WaterRecordForPoolService extends BaseService{
    constructor(){
        super('breeding/records/water-record-for-seed/pool') // 帶入 order為 resource
    }
    // 新增自定義方法：根據日期獲取記錄
    getDates(params) {
        return service.get(`/${this.resource}/dates/`, { params });
    }
}


// 將封裝的 class匯出
export default new WaterRecordForPoolService()