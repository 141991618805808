// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class WholeProductPositionService extends BaseService{
    constructor(){
        super('product/whole-product/position') 
    }

}

// 將封裝的 class匯出
export default new WholeProductPositionService()