// import axios from 'axios'
// const ShrimpSellRequest = axios.create({
//     baseURL: '/api/shrimp-sell/'
// })
  
import service from '@/api/service'
const url = "/sells"
  
  export const getShrimpSell = (params) => service.get(`${url}/`, {params})
  export const getShrimpSellGetPlaces = (params) => service.get(`${url}/places/`, {params})
  export const getShrimpSellGetDates = (params) => service.get(`${url}/dates/`, {params})
  export const createShrimpSell = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleShrimpSell = (id) => service.get(`${url}/${id}/`)
  export const updateShrimpSell = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { 'Content-Type': 'application/json' },
  })
  export const deleteShrimpSell = (id) => service.delete(`${url}/${id}/`)