
import service from '@/api/service'
const url = "/food/upload/image/color"
  
// 上傳檢查
export const uploadFoodColorImage = (data) => service.post(`${url}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
// 匯入系統
export const importFoodColorImage = (data) => service.post(`${url}/import/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
// 匯入系統
export const searchImportFoodColorImage = (data) => service.post(`${url}/import/search/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
