import { loadView } from "@/router/loadView"

export default [

    { // 公告欄
        path: '/f/announcement',
        name: 'AnnouncementListView',
        component: loadView("Announcement/AnnouncementListView"),
    },
    { // 單一公告欄
        path: '/f/announcement/detail/:id',
        name: 'AnnouncementDetailView',
        component: loadView("Announcement/AnnouncementDetailView"),
    },
    { // 創建公告欄
        path: '/f/announcement/create',
        name: 'CreateAnnouncement',
        component: loadView("Announcement/CreateAnnouncement"),
    },
    { // 修改公告欄
        path: '/f/announcement/update/:id',
        name: 'UpdateAnnouncement',
        component: loadView("Announcement/UpdateAnnouncement"),
    },

    { // 公告欄標籤
        path: '/f/announcement/tags',
        name: 'AnnouncementTagListView',
        component: loadView("Announcement/Tag/AnnouncementTagListView"),
    },
    { // 創建公告標籤
        path: '/f/announcement/tags/create',
        name: 'CreateAnnouncementTag',
        component: loadView("Announcement/Tag/CreateAnnouncementTag"),
    },
    { // 修改公告標籤
        path: '/f/announcement/tags/update/:id',
        name: 'UpdateAnnouncementTag',
        component: loadView("Announcement/Tag/UpdateAnnouncementTag"),
    },

    
];