// import axios from 'axios'
// const instance = axios.create({
//     baseURL: '/api/schedule/'
// })
  
import service from '@/api/service'
const url = "/schedule"
  
export const getSchedule = (params) => service.get(`${url}/`, {params})

export const getScheduleAnalysis = (params) => service.get(`${url}/analysis/`, {params})
export const getScheduleAnalysisHistory = (params) => service.get(`${url}/analysis/history/`, {params})
export const getScheduleAnalysisGrow = (params) => service.get(`${url}/analysis/grow/`, {params})
export const getScheduleAnalysisBonus = (params) => service.get(`${url}/analysis/bonus/`, {params})


export const getScheduleCatch = (params) => service.get(`${url}/catch/`, {params})
export const createSchedule = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
})

export const createBatchSchedule = (data) => service.post(`${url}/batch/`, data, )

export const getSingleSchedule = (id) => service.get(`${url}/${id}/`)
export const updateSchedule = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
})
export const deleteSchedule = (id) => service.delete(`${url}/${id}/`)