// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class ProductProcessedCommodityMaterialTypeService extends BaseService{
    constructor(){
        super('product/processed-product/commodity/material-type') 
    }

}

// 將封裝的 class匯出
export default new ProductProcessedCommodityMaterialTypeService()