// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class PositionService extends BaseService{
    constructor(){
        super('location/position') // 帶入 order為 resource
    }
    bulk_update(datas){
        return service.get(`${this.resource}/bulk-update/`, datas)
    }
}


// 將封裝的 class匯出
export default new PositionService()