import dayjs from "dayjs"
import router from '@/router'


const getDefaultState = () => { // state
    return {

        // PendingOrderSelectView中勾選的項目
        item_selected: sessionStorage.getItem("material_pending_item_selected")
                        ? JSON.parse(sessionStorage.getItem("material_pending_item_selected"))
                        : [],

        // 這個由待確認新增的運送訂單ID (用於顯示使用)
        pending_created_shipping_id: null,

        // 叫料管理的月份範圍(請搭配 session)
        material_management_range_date_idx: sessionStorage.getItem("material_management_range_date_idx")
                                    ? JSON.parse(sessionStorage.getItem("material_management_range_date_idx"))
                                    : [
                                        dayjs().startOf('year').format('YYYY-MM'), // 今年年初
                                        dayjs().format('YYYY-MM'), // 今年年底
                                    ]
     
        
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),

    mutations: { 
        setItemSelected(state, payload){ // 定義日期
            state.item_selected = payload
        },

        setPendingCreatedShippingID(state, payload){
            state.pending_created_shipping_id = payload
        },

        // 設定叫料管理的月份範圍
        setMaterialManagementRangeDateIdx(state, payload){
            state.material_management_range_date_idx = payload

            // 記錄到 session
            sessionStorage.setItem('material_management_range_date_idx', JSON.stringify(payload))
        },
    },
    
    actions: { 
        setItemSelected({state, commit}, payload){
            commit("setItemSelected", payload)

            // 將日期儲存至Session
            sessionStorage.setItem('material_pending_item_selected', JSON.stringify(payload))
        },
        removeItemSelected({state, commit}){
            commit("setItemSelected", [])
            sessionStorage.removeItem('material_pending_item_selected')
        },
    },

}