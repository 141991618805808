import service from '@/api/service'
const url = "/expected-sell"
  
  export const getExpectedSell = (params) => service.get(`${url}/`, {params})
  export const createExpectedSell = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleExpectedSell = (id) => service.get(`${url}/${id}/`)

  export const updateExpectedSell = (id, data) => service.put(`${url}/${id}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
  export const deleteExpectedSell = (id) => service.delete(`${url}/${id}/`)