// import axios from 'axios'
// const Instance = axios.create({
//     baseURL: '/api/feed_input/'
// })
  
      
import service from '@/api/service'
const url = '/feed_input/v2' // /api/temp
  
// 養殖紀錄 => 查詢所有場資料(含運作池數、)
export const getPositionInput = (id, params) => service.get(`${url}/position/input/${id}/`, {params})
export const getPositionInputDate = (id, params) => service.get(`${url}/position/input/${id}/dates/`, {params})
export const getPositionInputUpdateDate = (id, params) => service.get(`${url}/position/input/${id}/update-date/`, {params})

export const getPositionInputPoolDetail = (params) => service.get(`${url}/position/input/pools/detail/`, {params})

//#endregion