// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class SellService extends BaseService{
    constructor(){
        super('sells') // 帶入 order為 resource
    }
    

    get_places(params) {
        return service.get(`/${this.resource}/places/`, { params });
    }
    get_dates(params) {
        return service.get(`/${this.resource}/dates/`, { params });
    }
    get_total(params) { // 總額
        return service.get(`/${this.resource}/total/`, { params });
    }
    get_positions(params) { // 案場總額
        return service.get(`/${this.resource}/positions/`, { params });
    }
    get_circuits(params) { // 迴路總額
        return service.get(`/${this.resource}/circuits/`, { params });
    }
}


// 將封裝的 class匯出
export default new SellService()