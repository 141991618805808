// import axios from 'axios'
// const Instance = axios.create({
//     baseURL: '/api/taiwan-regions/'
// })

import service from '@/api/service'
const url = "/taiwan-regions"
  
  export const getTaiwanRegions = (params) => service.get(`${url}/`, {params})
  export const getSingleTaiwanRegions = (id, params) => service.get(`${url}/${id}/`, {params})
