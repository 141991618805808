// import axios from 'axios'


// const Instance = axios.create({
//     baseURL: '/api/food-stuff/product-schedule/',
//     withCredentials: true, // default
// })

import service from '@/api/service'
const url = '/food-stuff/product-schedule' // /api/temp

/*  */
export const getFoodProductScheduleDates = (params) => service.get(`${url}/dates/`, {params})
