// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class ExternalInspectTasteService extends BaseService{
    constructor(){
        super('food/external-inspect/tastes') // 帶入 order為 resource
    }

    get_avg_taste_by_product(params) {
        return service.get(`/${this.resource}/avg/product/`, { params }); 
    }
}

// 將封裝的 class匯出
export default new ExternalInspectTasteService()