import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (病毒檢驗)
    { /* 病毒檢驗 */
        path: '/shrimp-virus',
        name: 'ShrimpVirus',
        component: loadView("VirusManagement/ShrimpVirus"),
        meta: { roles: ['admin'], keepAlive: true }  // 需要為 Admin
    },
    //#endregion


    //#region (查詢各種不同對象的病毒檢驗)
    {   /* 病毒檢驗(同個病毒排程) */
        path: '/shrimp-virus/schedule/:id',
        name: 'VirusByVirusSchedule',
        component: loadView("VirusManagement/ShrimpVirus/VirusByVirusSchedule"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    {   /* 病毒檢驗(同個養殖排程) */
        path: '/shrimp-virus/feed-schedule/:id',
        name: 'VirusByFeedSchedule',
        component: loadView("VirusManagement/ShrimpVirus/VirusByFeedSchedule"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    {   /* 病毒檢驗(同個苗源排程) */
        path: '/shrimp-virus/seed-schedule/:id',
        name: 'VirusBySeedSchedule',
        component: loadView("VirusManagement/ShrimpVirus/VirusBySeedSchedule"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    //#endregion

];