// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class TransferScheduleService extends BaseService{
    constructor(){
        super('transfer-sell/schedule') // 帶入 order為 resource
    }

    create() {
        throw new Error('不允許執行');
    }

    update() {
        throw new Error('不允許執行');
    }

    delete() {
        throw new Error('不允許執行');
    }

    // 得到同一個月份的排程
    get_same_month_list(id, params) {
        return service.get(`/${this.resource}/same-month-list/${id}/`, { params });
    }
}


// 將封裝的 class匯出
export default new TransferScheduleService()