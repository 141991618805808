// import axios from 'axios'
// const FileRequest = axios.create({
//     baseURL: '/api/file/'
// })
  
import service from '@/api/service'
const url = "/file"
  
//   export const downloadFile = (file) => service.get(`${url}/download/?filename=${file}.xlsx`)
  export const downloadFile = (data) => service.post(`${url}/download/`, data, {
            responseType: 'blob',
            headers: {'Content-Type': 'multipart/form-data'}
      },
  )


  export const exportExcelFile = (data) => service.post(`${url}/export_excel/`, data, {
      headers: {'Content-Type': 'multipart/form-data'},
      // onDownloadProgress: (progressEvent) => {
      //       let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //       console.log(progressEvent.lengthComputable);
      //       console.log(percentCompleted);
      // }
})