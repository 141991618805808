// /* 蝦苗生產紀錄 */
// import axios from 'axios'
// const instance = axios.create({
//     baseURL: '/api/stations/pools/'
// })
  
import service from '@/api/service'
const url = "/stations/pools"


  export const getStationPool = (params) => service.get(`${url}/`, {params})


  export const getSingleStationPool = (id) => service.get(`${url}/${id}/`)
