// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class SeedScheduleService extends BaseService{
    constructor(){
        super('origin/seed/schedule') // 帶入 order為 resource
    }

    get_dates(params) {
        return service.get(`/${this.resource}/dates/`, {params});
    }

}

// 將封裝的 class匯出
export default new SeedScheduleService()