import { loadView } from "@/router/loadView"

export default [
    { /* 出貨調控紀錄 */
        path: '/transfer/for-energy/sell',
        name: 'TransferSellForEnergyView',
        component: loadView("TransferSell/TransferForEnergyManagement/TransferSellForEnergyView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 出貨列表 */
        path: '/transfer/for-energy/sell-list',
        name: 'TransferSellForEnergySellListView',
        component: loadView("TransferSell/TransferForEnergyManagement/TransferSellForEnergySellListView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 案場目標設定 */
        path: '/transfer/for-energy/position/configuration',
        name: 'TransferPositionConfigurationView',
        component: loadView("TransferSell/TransferForEnergyManagement/TransferPositionConfigurationView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 計畫安排 */
        path: '/transfer/for-energy/planning',
        name: 'TransferForEnergyPlanningView',
        component: loadView("TransferSell/TransferForEnergyManagement/TransferForEnergyPlanningView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 計畫安排(單一Plan) */
        path: '/transfer/for-energy/planning/:id',
        name: 'TransferForEnergyPlanningDetailView',
        component: loadView("TransferSell/TransferForEnergyManagement/Planning/TransferForEnergyPlanningDetailView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 計畫安排(指定案場) */
        path: '/transfer/for-energy/planning/:id/position/:position_id',
        name: 'TransferForEnergyPlanningPositionView',
        component: loadView("TransferSell/TransferForEnergyManagement/Planning/Detail/TransferForEnergyPlanningPositionView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },


    { /* 儀錶板 */
        path: '/transfer/for-energy/dashboard',
        name: 'TransferForEnergyDashboardView',
        component: loadView("TransferSell/TransferForEnergyManagement/TransferForEnergyDashboardView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 地區 */
        path: '/transfer/for-energy/dashboard/area/:id',
        name: 'TransferForEnergyDashboardAreaDetail',
        component: loadView("TransferSell/TransferForEnergyManagement/Dashboard/TransferForEnergyDashboardAreaDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 公司 */
        path: '/transfer/for-energy/dashboard/company/:id',
        name: 'TransferForEnergyDashboardCompanyDetail',
        component: loadView("TransferSell/TransferForEnergyManagement/Dashboard/TransferForEnergyDashboardCompanyDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 迴路 */
        path: '/transfer/for-energy/dashboard/circuit/:id',
        name: 'TransferForEnergyDashboardCircuitDetail',
        component: loadView("TransferSell/TransferForEnergyManagement/Dashboard/TransferForEnergyDashboardCircuitDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 案場 */
        path: '/transfer/for-energy/dashboard/position/:id',
        name: 'TransferForEnergyDashboardPositionDetail',
        component: loadView("TransferSell/TransferForEnergyManagement/Dashboard/TransferForEnergyDashboardPositionDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },


];