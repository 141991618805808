import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import { loadView } from "@/router/loadView"

Vue.use(VueRouter)

// 封包
import FeedRecordRoutes from '@/router/config/feedRecordRoutes'
import ExternalProductRoutes from '@/router/config/ExternalProductRoutes'
import AnnouncementRoutes from '@/router/config/AnnouncementRoutes'
import WaterRecodForSeedRoutes from '@/router/config/WaterRecodForSeedRoutes'
import BreedingManagementRoutes from '@/router/config/breedingManagementRoutes'
import OperatationManagementRoutes from '@/router/config/operatationManagementRoutes'
import ReportManagementRoutes from '@/router/config/reportManagementRoutes'
import FoodProcessingRoutes from '@/router/config/foodProcessingRoutes'
import VirusRoutes from '@/router/config/virusRoutes'
import SproutManagementRoutes from '@/router/config/sproutManagementRoutes'
import StationMapRoutes from '@/router/config/stationMapRoutes'
import OthersRoutes from '@/router/config/othersRoutes'
import BonusManagement from '@/router/config/bonusManagement'
import TeamManagementRoutes from '@/router/config/teamManagementRoutes'
import SystemManagementRoutes from '@/router/config/systemManagementRoutes'
import hydrologyManagementRoutes from '@/router/config/hydrologyManagementRoutes'
import historyTransferManagementRoutes from '@/router/config/historyTransferManagementRoutes'
import TransferSellForEnergyRoutes from '@/router/config/TransferSellForEnergyRoutes'

const routes = [
  { // 『/』導引到 『/f ({name: Home})』
    path: '/',
    redirect: (to) => ({ name: "Home" }),
  },

  { // 登入
    path: '/login',
    name: 'LoginView',
    component: loadView("Auth/LoginView"), // 非同步載入: 有需要時才載入元件(lazy-loading)
  },

  { // 首頁
    path: '/f',
    name: 'Home',
    component: loadView("Home"), // 非同步載入: 有需要時才載入元件(lazy-loading)
    meta: {
      is_menu: true,
      title: '首頁',
      icon: 'home',
    }
  },
  
  // 外部產品
  ...ExternalProductRoutes,

  // 公告
  ...AnnouncementRoutes,
  
  // 案場水質
  ...WaterRecodForSeedRoutes,

  // 1️⃣(養殖紀錄 - FeedRecord)
  ...FeedRecordRoutes,

  // 2️⃣(養殖管理 - BreedingManagement)
  ...BreedingManagementRoutes,

  // 3️⃣(營運管理 - OperatationManagement)
  ...OperatationManagementRoutes,

  // 4️⃣(報表管理 - ReportManagment)
  ...ReportManagementRoutes,
  

  // 4️⃣(獎金管理 - BonusManagement)
  ...BonusManagement,

  // 5️⃣(竹田食品加工 - FoodProcessing)
  ...FoodProcessingRoutes,

  // 6️⃣(病毒檢驗)
  ...VirusRoutes,


  // 5️⃣(分組管理 - GroupManagement)
  ...TeamManagementRoutes,

  // 5️⃣(系統管理 - SystemManagement)
  ...SystemManagementRoutes,

  // 5️⃣(轉移數據 - historyTransferManagement)
  ...historyTransferManagementRoutes,

  // 經濟規劃書(附屬綠能)
  ...TransferSellForEnergyRoutes,

  { /* 用戶資訊處理 */
    path: '/my/profile',
    name: 'UserProfile',
    component: loadView("Auth/User/UserProfile"),
    meta: { roles: ['admin', 'manager', 'team_leader', 'editor', 'accountant', 'marketing', 'data_controller']} // 需要為 Admin
  },
  

  { /* 註冊功能 */
    path: '/user/register',
    name: 'UserRegister',
    component: loadView("Auth/User/UserRegister"),
    meta: { roles: ['admin', 'manager', 'team_leader', 'editor', 'accountant', 'marketing', 'data_controller']} // 需要為 Admin
  },

  { /* 7️⃣JNC資料 */
    path: '/jnc-demo',
    name: 'JNCDemo',
    component: loadView("Others/JNC/JNCDemo"),
    meta: { roles: ['admin'] } // 需要為 Admin
  },

  { // 8️⃣M10太陽能板測試
    path: '/m10-panel',
    name: 'M10_PanelView',
    component: loadView("Others/M10_Panel/M10_PanelView"),
    meta: { roles: ['admin'] } // 需要為 Admin
  },


  // 9️⃣(蝦苗管理 - SproutManagement)
  ...SproutManagementRoutes,

  // 🔟(場域地圖 - StationMap)
  ...StationMapRoutes,

  // 🔟(水文地圖 - hydrologyManagementRoutes)
  ...hydrologyManagementRoutes,

  // ❤️(其他路由 - Other)
  ...OthersRoutes,


  /* 把 404 Page放置到最下面(*) */
  {
    path: '/*',
    name: 'NotFoundPage',
    component: loadView("NotFoundPage"),
  },
]

// 解決重複點擊路由所報錯的BUG
// 【資料來源】https://blog.juanertu.com/archives/721f45a5
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: "history",
  routes
})


router.beforeEach(async (to, from, next) => {

  const { user_info } = store.state['auth']; // 獲取登入後的使用者狀態
  const user_role = user_info?.role || null; // 當前登入用戶的角色
  const user_roles = user_info?.roles || null; // 當前登入用戶的角色(陣列)
  const meta_roles = to.meta?.roles || null; // 目標路由的角色限制

  // 尚未登入就直接去登入
  if(to.name !== 'LoginView' && !user_info){
    next({name: "LoginView"})
  }

  // 已經登入就不能再進入登入頁面
  if(to.name === 'LoginView' && user_info){
    next({ name: 'Home' })
  }


  //#region (權限角色驗證)
  // if( meta_roles ){ // 如果有限制
  //   // 多個使用者角色
  //   if(user_roles && meta_roles.some(role => user_roles.includes(role))){
  //     next()
  //   }
  //   // 單一使用者
  //   else if (user_role && meta_roles.includes(user_role)) { // 有符合限制 => 成功瀏覽
  //     next()
  //   } 

  //   else { // 不有符合限制 => 導回首頁
  //     next({ name: 'Home' })
  //   }
  // }

  // 權限角色驗證
  if (meta_roles) {
    const hasAccess = meta_roles.some(
                      (role) => 
                        user_roles.includes(role) 
                      || user_role === role
                    );


    // 如果驗證不過就回到首頁
    if (!hasAccess) {
      return next({ name: 'Home' });
    }
  }
  //#endregion
  
  
  

  next() // 如果以上條件都通過，則允許直接前往該路由
})
export default router
