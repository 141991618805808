import { loadView } from "@/router/loadView"
export default [
    //#region 【1】 (苗源分析)
    { /* 苗源分析 */
        path: '/shrimp-seed',
        name: 'ShrimpSeed',
        component: loadView("OperatationManagement/ShrimpSeed/ShrimpSeed"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] } // 需要為 Admin或 Manager
    },

    { /* 苗源分析(同苗源) */
        path: '/shrimp-seed/view/:id',
        name: 'ShrimpSeedView',
        component: loadView("OperatationManagement/ShrimpSeed/ShrimpSeedView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] } // 需要為 Admin或 Manager
    },
    //#endregion

    { /* 苗源管理 */
        path: '/management/seed/view',
        name: 'SeedManagementView',
        component: loadView("OperatationManagement/SeedManagementView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'], keepAlive: true } // 需要為 Admin或 Manager
    },

    { /* 苗源詳細資訊 */
        path: '/management/seed/view/:id',
        name: 'SeedManagementDetailView',
        component: loadView("OperatationManagement/SeedManagementView/SeedManagementDetailView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] } // 需要為 Admin或 Manager
    },


    //#region 【2】 (排程分析[運行中])

    {
        path: '/shrimp-schedule/view',
        name: 'ScheduleAnalysis',
        component: loadView("OperatationManagement/ScheduleAnalysis"),
        meta: { roles: ['admin', 'manager', 'team_leader'], keepAlive: true } // 需要為 Admin或 Manager
    },
    //#endregion

    //#region 【3】 (排程分析[歷史資料 - 預設去年])
    {
        path: '/shrimp-schedule/history/view',
        name: 'ScheduleAnalysisHistory',
        component: loadView("OperatationManagement/ScheduleAnalysisHistory"),
        meta: { roles: ['admin', 'manager', 'team_leader'], keepAlive: true } // 需要為 Admin或 Manager
    },

    //#endregion

    //#region 【4】 (收成預測)
    {
        path: '/harvest-analysis',
        name: 'ScheduleHarvestPredict',
        component: loadView("OperatationManagement/ScheduleHarvestPredict"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'marketing', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【5】 (成長分析)
    {
        path: '/grow-analysis',
        name: 'ScheduleGrowAnalysis',
        component: loadView("OperatationManagement/ScheduleGrowAnalysis"),
        meta: { roles: ['admin', 'manager', 'team_leader'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【6】 (出貨管理)
    {
        path: '/sell-management/',
        name: 'SellManagement',
        component: loadView("OperatationManagement/SellManagement"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant', 'marketing'], keepAlive: true } // 需要為 Admin或 Manager
    },
    //#endregion

    //#region 【6】 (排程輸入檢查)
    {
        path: '/schedule-alert-input/',
        name: 'ScheduleAlertInput',
        component: loadView("OperatationManagement/ScheduleAlertInput"),
        meta: { roles: ['admin', 'manager'], keepAlive: true } // 需要為 Admin或 Manager
    },
    //#endregion

    //#region 戰情中心
    {
        path: '/dashboard-center/',
        name: 'DashboardCenter',
        component: loadView("OperatationManagement/DashboardCenter"),
        meta: { roles: ['admin', 'manager'], keepAlive: true } // 需要為 Admin或 Manager
    },
    {
        path: '/dashboard-center/position/:id',
        name: 'PositionDashboardCenter',
        component: loadView("OperatationManagement/DashboardCenter/PositionDashboardCenter"),
        meta: { roles: ['admin', 'manager'], keepAlive: true } // 需要為 Admin或 Manager
    },
    //#endregion

];