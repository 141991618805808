// import axios from 'axios'
// const TempRequest = axios.create({
//     baseURL: '/api/temp/'
// })
      
import service from '@/api/service'
const url = '/temp' // /api/temp

  
export const getTempStation = (params) => service.get(`${url}/temp-station/`, {params})
/* 【修正成長曲線】 */
export const getTempUpdateGrow = (params) => service.get(`${url}/temp-station/update-grow/`, {params})
/* 【標準成長曲線】 */
export const getTempStandardGrow = (params) => service.get(`${url}/temp-station/standard-grow/`, {params})

// 還用得到 (養殖記錄取得即時天氣)
export const getStation = (params) => service.get(`${url}/station/`, {params})