// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"

class ExternalInspectFullnessListService extends BaseService{
    constructor(){
        super('food/external-inspect/fullness/lists') // 帶入 order為 resource
    }
}

// 將封裝的 class匯出
export default new ExternalInspectFullnessListService()