// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class HydrologyExternalStationService extends BaseService{
    constructor(){
        super('hydrology/external/station') 
    }
}


// 將封裝的 class匯出
export default new HydrologyExternalStationService()