// import axios from 'axios'
// const service = axios.create({
//     baseURL: '/api/empty-manage/'
// })
  
import service from '@/api/service'
const url = '/empty-manage' // /api/temp

// 查詢全部空池管理(管理者)
export const getEmptyManageList = (params) => service.get(`${url}/`, {params})

// 查詢單一空池管理(管理者)
export const getEmptyManageDetail = (id, params) => service.get(`${url}/${id}/`, {params})

// 新增空池管理(管理者)
export const createEmptyManage = (data) => service.post(`${url}/`, data, )

// 更新空池管理(管理者)
export const patchEmptyManage = (id, data) => service.patch(`${url}/${id}/`, data, )