import dayjs from "dayjs"
import router from '@/router'


const getDefaultState = () => { // state
    return {
        range_date_idx: [ // 生產日期範圍
            sessionStorage.getItem("food_inspect_range_date_idx")
                ? JSON.parse(sessionStorage.getItem("food_inspect_range_date_idx"))[0]
                : dayjs().subtract(3, 'month').format("YYYY-MM-DD"),
            sessionStorage.getItem("food_inspect_range_date_idx")
                ? JSON.parse(sessionStorage.getItem("food_inspect_range_date_idx"))[1]
                : dayjs().format("YYYY-MM-DD")
        ],


        // 日期範圍的變化 => 當資料有變動時會觸發為true
        date_loading: false,

        // 案場列表
        positions: [],

        // 圖表顯示的數據
        chart_display_item_ids: []
        
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),

    mutations: { 
        setRangeDateIdx(state, payload){ // 定義日期
            state.range_date_idx = payload
            
            // 將日期儲存至Session
            sessionStorage.setItem('food_inspect_range_date_idx', JSON.stringify(payload))
        },
        setDateLoading(state, payload){ // 日期範圍變動
            state.date_loading = payload
        },

        setPositions(state, payload){
            state.positions = payload
        }, 

        setChartDisplayItemIDs(state, payload){
            state.chart_display_item_ids = payload
        },
    },
    
    actions: { 
      changeChartDisplayIDs({state, commit}, payload){
        commit("setChartDisplayItemIDs", payload)

      },

    },

}