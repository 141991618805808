import { loadView } from "@/router/loadView"
export default [
    //#region 【1】 (場域地圖)
    { // 場域地圖
        path: '/station',
        name: 'Station',
        component: loadView("PositionStation/Station"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'], keepAlive: true} // 需要為 Admin
      },
      { /* 功能測試區 */
        path: '/station/:name',
        name: 'StationView',
        component: loadView("PositionStation/StationView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] } // 需要為 Admin 
      },
    //#endregion

 


];