<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="d-flex flex-column justify-center align-center pa-1 pa-md-6   pa-lg-8">
            <v-icon style="font-size: 3.5rem;" color="light-blue lighten-2">mdi-information-outline</v-icon>
            <v-card-text class="text-center">
                <h3 class="font-weight-bold text-h4 my-4">安全問題設定</h3>
                <div class="d-flex flex-column my-4 gap-2 text-subtitle-1 text-sm-h6 black--text">
                    <span>使用者您好! 您目前尚未設定安全問題</span>
                    <span class="text-caption text-sm-subtitle-2 grey--text text--darken-2 font-weight-bold">PS:
                        當密碼忘記時可以透過安全問題進行重設密碼</span>

                </div>

                <div class="d-flex justify-center align-center">
                    <v-checkbox class="pa-0 ma-0" v-model="is_check_no_show_security" label="今日不再顯示" hide-details
                        color="grey"></v-checkbox>
                </div>


                <div class="mt-4 d-flex justify-center gap-4">
                    <v-btn :small="is_mobile" color="primary" @click="goToUserProfileView">
                        前往設定
                    </v-btn>
                    <v-btn :small="is_mobile" color="secondary" @click="closeDialog">
                        關閉視窗
                    </v-btn>
                </div>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
export default {
    props: {
 
    },

    data(){
        return {
            dialog: false,
            is_check_no_show_security: false, // [勾選按鈕]: 今日不再顯示
        }
    },

    mounted(){
        // 判斷該用戶是否已經設定安全問題
        if(!Cookies.get('no_show_security')){
            if( !this.is_staff && this.profile ){ // 不能微系統帳好且要有 profile
                // 確認是否有安全問題
                this.dialog = this.profile.security_question 
                                    ? false 
                                    : true
            }
        }
    },

    computed: {
        ...mapState('auth', {
            profile: state => state.user_info['profile'],
            is_staff: state => state.user_info['is_staff'],
        }),
    },

    methods: {
        goToUserProfileView() { // 前往設定
            this.security_question_dialog = false
            this.$router.push({
                name: "UserProfile"
            })
        },

        closeDialog(){
            if( this.is_check_no_show_security ){
                Cookies.set('no_show_security', true, { expires: 1 })
            }
            this.dialog = false
            this.$swal.fire("設定提醒", "如需設定時，請點擊『右上角的使用者設定』", "info")
        },
    }
}
</script>

<style></style>