import { loadView } from "@/router/loadView"
export default [
   
    //#region 【1】 (獎金月報表)
    {
        path: '/bonus-month-report',
        name: 'ScheduleBonusMonthReport',
        component: loadView("BonusManagement/BonusMonthReport"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion
];