// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"

class FoodTasteMemberService extends BaseService{
    constructor(){
        super('food/inspect/taste/member') // 帶入 order為 resource
    }
}

// 將封裝的 class匯出
export default new FoodTasteMemberService()