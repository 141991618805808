// import axios from 'axios'
// const Instance = axios.create({
//     baseURL: '/api/report-manage/'
// })

import service from '@/api/service'
const url = "/report-manage"
  

  
export const getMonthReport = (params) => service.get(`${url}/month-report-list/`, {params})
export const getMonthReportExport = (params) => service.get(`${url}/month-report-list/export/`, {params})
export const getFeedManage = (params) => service.get(`${url}/feed-manage-list/`, {params})
export const getEmptyManage = (params) => service.get(`${url}/empty-manage-list/`, {params})
