// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class BreedingRecordFeedRecordService extends BaseService{
    constructor(){
        super('breeding_record/feed_record') // 帶入 order為 resource
    }
    // 得到所有案場列表數據
    get_positions(params) {
        return service.get(`/${this.resource}/positions/`, { params });
    }

    // 得到該案場的數據
    get_positions_by_id(id, params) {
        return service.get(`/${this.resource}/positions/${id}/`, { params });
    }

    // 得到排程的養殖圖表
    get_schedule_chart(id, params) {
        return service.get(`/${this.resource}/schedule/${id}/chart/`, { params });
    }

    // 得到案場的養殖圖表
    get_position_chart(id, params) {
        return service.get(`/${this.resource}/position/${id}/chart/`, { params });
    }

}


// 將封裝的 class匯出
export default new BreedingRecordFeedRecordService()