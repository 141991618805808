import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (收成月報表)
    {
        path: '/ReportManagement/havest-month-report',
        name: 'HarvestMonthView',
        component: loadView("ReportManagement/HarvestMonthView"),
        meta: { roles: ['admin', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【2】 (2023年報-排程收成)
    {
        path: '/ReportManagement/schedule-history/:year',
        name: 'ScheduleHistoryYear',
        component: loadView("ReportManagement/ScheduleHistoryYear"),
        meta: { roles: ['admin'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【3】 (2023年報-收成報表)
    {
        path: '/ReportManagement/harvest-history/:year',
        name: 'HarvestHistoryYearView',
        component: loadView("ReportManagement/HarvestHistoryYearView"),
        meta: { roles: ['admin', 'accountant'], keepAlive: true } // 需要為 Admin
    },

    //#endregion


    //#region 【3】 (歷史年報表)
    { // 年份列表
        path: '/ReportManagement/history-report',
        name: 'HistoryYearList',
        component: loadView("ReportManagement/HistoryYearList"),
        meta: { roles: ['admin', 'accountant']} // 需要為 Admin
    },

    { // 歷史收成年報
        path: '/ReportManagement/history-report/harvest/:year',
        name: 'HarvestHistoryYearReport',
        component: loadView("ReportManagement/HarvestHistoryYearReport"),
        meta: { roles: ['admin', 'accountant']} // 需要為 Admin
    },

    { // 歷史排成收成年報
        path: '/ReportManagement/history-report/schedule/:year',
        name: 'ScheduleHistoryYearReport',
        component: loadView("ReportManagement/ScheduleHistoryYearReport"),
        meta: { roles: ['admin', 'accountant']} // 需要為 Admin
    },
    //#endregion

    //#region 【4】 (獎金系統)
    {
        path: '/bonus-analysis',
        name: 'ScheduleBonusAnalysis',
        component: loadView("ReportManagement/ScheduleBonusAnalysis"),
        meta: { roles: ['admin', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【5】 (總目標管理)
    {
        path: '/total-annual-kpi',
        name: 'TotalAnnualKPIManagement',
        component: loadView("ReportManagement/TotalAnnualKPIManagement"),
        meta: { roles: ['admin'], keepAlive: false } // 需要為 Admin
    },
    //#endregion
];