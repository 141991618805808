import dayjs from 'dayjs'
import { apiExportExcelFile, apiDownloadFile } from "@/api/"
export const globalMixins = {
    mounted() {
        // Ensure that the component is mounted before accessing $el
        if (this.$el instanceof HTMLElement) {
            // 在元件被加載後，選取所有 type 為 'number' 的 input 元素
            const numberInputs = this.$el.querySelectorAll('input[type="number"]');
            // 針對每個找到的 input 元素，加上事件監聽器來阻止滾動行為
            numberInputs.forEach((input) => {
                input.addEventListener('wheel', (e) => {
                    e.preventDefault(); // 將該事件關閉
                });
            });
            
        }
    },
    data(){
        return {
            window_height: window.innerHeight,
        }
    },
    computed:{
        // 查看手機目前介面的size (xs → sm → md → lg → xl)【由小排到大】
        rwd_name(){
            return this.$vuetify.breakpoint.name;
        },
        is_mobile(){ // 是否為手機介面(vuetify)
            return this.rwd_name === 'xs'
        },
        is_tablet(){ // 是否為平板介面(vuetify)
            return this.rwd_name === 'sm'
        },
        dialogWidth(){ // Dialog指定寬度
            const {rwd_name} = this
            const map_width = {
                'xs': "90%",
                'sm': "70%",
                'md': "50%",
                'lg': "50%",
                'xl': "30%",
            }
            return map_width[rwd_name]
        },
        dialogFullWidth(){ // Dialog指定寬度
            const {rwd_name} = this
            const map_width = {
                'xs': "90%",
                'sm': "80%",
                'md': "70%",
                'lg': "60%",
                'xl': "50%",
            }
            return map_width[rwd_name]
        },
        dialogVeryWidth(){ // Dialog指定寬度
            const {rwd_name} = this
            const map_width = {
                'xs': "90%",
                'sm': "85%",
                'md': "80%",
                'lg': "70%",
                'xl': "70%",
            }
            return map_width[rwd_name]
        },
    },

    created() {
        window.addEventListener("resize", this.resizeWidowFunction);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWidowFunction);
    },

    methods: {
        resizeWidowFunction(e) {
            this.window_height = window.innerHeight;
        },

        // 解決四捨五入精密度所帶來的問題
        roundToTwo(num, decimal=2) { // 預設小數點第二位
            return +(Math.round(num + `e+${decimal}`)  + `e-${decimal}`);
            // return +(Math.round(num + "e+2")  + "e-2");
        },
        
        // 轉換數值(回傳: 萬)
        transformNumber(number) { 
            if(number >=10000){
                const transformed = this.roundToTwo( number / 10000 )
                return `${transformed}萬`
            }
            return number
        },
        
        // 轉換重量單位(回傳: 公斤、噸)
        transformKilogram(kilogram) { 
            if(Math.abs(kilogram) >=1000){
                const transformed = this.roundToTwo( kilogram / 1000 )
                return `${transformed}噸`
            }
            return `${kilogram}公斤`
        },
        
        // 轉換數量(單位: 萬)
        transformAmount(amount) { 
            return this.roundToTwo( amount / 10000 )
        },

        // 轉成百分比
        transformPercentage(num){ 
            return `${Math.round( num * 10000 ) / 100}%`
        },
        
        // 轉成$$符號
        transformMoneyUnit(num){
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                // 如果不想要小數點，可以加上這行：
                minimumFractionDigits: 0
            }).format(num);
        },
        
        // 計算兩個日期相差幾天
        diffDay(d1, d2){ 
            const d1_dt = dayjs(d1)
            const d2_dt = dayjs(d2)
            return d2_dt.diff( d1_dt, 'day', true )
        },
        
    
        padTo2Digits(num) { /* 轉換時間字串 */
            return num.toString().padStart(2, "0");
        },
        
        /* 轉換時間字串 (datetime.date → String ) */
        formatDate(formatedDate, output='yyyymm'){ 
            const date = new Date(formatedDate)
            const year = this.padTo2Digits( date.getFullYear() )
            const month = this.padTo2Digits( date.getMonth() + 1 )
            const day = this.padTo2Digits( date.getDate())
            if(output === 'yyyymmdd' ){
                return `${year}-${month}-${day}`
            }
            return `${year}-${month}`
        },
        
        /* 轉換會計符號 */
        formatAccounting(number) {
            // 檢查數字是否為負數
            const isNegative = number < 0;
        
            // 取絕對值並格式化數字，包括千位數逗號分隔
            const formattedNumber = Math.abs(number).toLocaleString(undefined, {
                // minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        
            // 如果是負數，添加負號
            return isNegative ? `(${formattedNumber})` : formattedNumber;
        },
        
        // 遇到\n進行<br>換行轉換
        formatNewLine(text){
            return text.replace(/\n/g, '<br>')
        },

    
        // 【於前端下載檔案的方法】
        // https://www.itread01.com/article/1537510973.html
        download( data, filename, ext="xlsx" ){
            if (!data) return // 沒給資料就掰掰
    
            let url = window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `${filename}.${ext}`)
    
            document.body.appendChild(link)
            link.click()
        },
    
        // 將資料建置成暫存 Excel
        async exportExcel(datas, output_name, image=null, images={} ){
            const formData = new FormData()
            // 這邊要把data轉換成 String傳回 Django進行轉換檔案
            formData.append( "data", JSON.stringify(datas)) 
            
            if(image){ // 溫度計會使用到 (base64Image)
                formData.append( "image", image) 
            }

            if(Object.keys(images).length > 0){ // 溫度計會使用到 (base64Image)
                formData.append( "images", JSON.stringify(images)) 
            }

            try{
                const response = await apiExportExcelFile(formData)
                const statusCode = response.status
                if(statusCode === 200){
                    const formData = new FormData()
                    try{
                        const response = await apiDownloadFile(formData)
                        await this.download(response.data, output_name, 'xlsx')
                    }catch(err){
                        console.log(`匯出報表時發生錯誤: ${err}`);
                        this.$swal.fire( "匯出中止", "發生不明錯誤", "error" )
                    }
                }
            }catch(err){
                console.log(err);
            }
            finally{
            }
        },


        
        
    }

}