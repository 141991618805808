import permission from "./permission";

// 欲註冊的 Directive 名稱及其實作物件，並將其實作物件寫在字典裡。
const directives = {
    permission
}

// 透過 vue.directives實現批次註冊
export default{
    install(Vue){
        for(const [key, val] of Object.entries(directives)){
            Vue.directive(key, val)
        }
    },
}