// import axios from 'axios'
// const SeedScheduleRequest = axios.create({
//     baseURL: '/api/seed-schedule/'
// })
  
import service from '@/api/service'
const url = '/seed-schedule' // /api/temp
  
  
  export const getSeedSchedule = (params) => service.get(`${url}/`, {params})
  export const getSeedScheduleDate = (params) => service.get(`${url}/dates/`, {params})
  export const getSeedScheduleDetail = (params) => service.get(`${url}/detail/`, {params})
  export const getSeedScheduleFeedsDetail = (params) => service.get(`${url}/feeds/detail/`, {params})
  export const createSeedSchedule = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleSeedSchedule = (id, params) => service.get(`${url}/${id}/`, {params})
  export const updateSeedSchedule = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })
  export const deleteSeedSchedule = (id) => service.delete(`${url}/${id}/`)