import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (系統管理)
    { /* 用戶管理 */
        path: '/system/user/',
        name: 'UserManagement',
        component: loadView("SystemManagement/UserManagement"),
        meta: { roles: ['admin', 'manager', 'team_leader'] } // 需要為 Admin
    },
    { /* 角色管理 */
        path: '/system/group/',
        name: 'GroupManagement',
        component: loadView("SystemManagement/GroupManagement"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    { /* 小組管理 */
        path: '/system/team/',
        name: 'TeamManagement',
        component: loadView("SystemManagement/TeamManagement"),
        meta: { roles: ['admin', 'manager', 'team_leader'] } // 需要為 Admin
    },

    //#endregion

];