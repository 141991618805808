// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class TransferDashboardService extends BaseService{
    constructor(){
        super('transfer-sell/dashboard') // 帶入 order為 resource
    }

    create() {
        throw new Error('不允許執行');
    }

    update() {
        throw new Error('不允許執行');
    }

    delete() {
        throw new Error('不允許執行');
    }

    get_area(params){
        return service.get(`/${this.resource}/area/`, { params });
    }
    get_area_by_id(id, params){
        return service.get(`/${this.resource}/area/${id}/`, { params });
    }

    get_company(params){
        return service.get(`/${this.resource}/company/`, { params });
    }
    get_company_by_id(id, params){
        return service.get(`/${this.resource}/company/${id}/`, { params });
    }

    get_circuit(params){
        return service.get(`/${this.resource}/circuit/`, { params });
    }

    get_circuit_by_id(id, params){
        return service.get(`/${this.resource}/circuit/${id}/`, { params });
    }

    get_position(params){
        return service.get(`/${this.resource}/position/`, { params });
    }
 
}


// 將封裝的 class匯出
export default new TransferDashboardService()