import service from '@/api/service'
const url = '/food/upload/excel/ice'

// 上傳Excel
export const uploadIceExcel = (data) => service.post(`${url}/`, data, {
    headers: {
    'Content-Type': 'multipart/form-data',
    },
})

// 進行匯入
export const importIceExcel = (data, config) => service.post(`${url}/import/`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
        ...config
    })

// 下拉查看加工品檢驗是否已經匯入
export const searchImportIceExcel = (data, config) => service.post(`${url}/import/search/`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
        ...config
    })

