import { apiGetDailyReport  } from '@/api/'
import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {
        record_date: null,
        selected_headers: [], // 勾選的 Headers
    }
}



export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setRecordDate(state, payload){
            state.record_date = payload
        },
        setSelectedHeaders(state, payload){
            state.selected_headers = payload
        },
    },
    
    actions: { 
        caculateGrowRate({state, commit, actions}, records ){ // 計算成長率
            if(records.length === 0) return 0
            if(records.length === 1) return 1 // 只有一筆那就成長率為1囉
            const last_weight = records[0]['weight'] // 因為有用 reverse，因此 index=0為最新
            const prev_weight = records[1]['weight'] // 因為有用 reverse，因此 index=1為前一筆
            if (!prev_weight  || !last_weight ) return 1
            // 周成長率 = (最新 + 前一筆) ÷ 前一筆
            const grow_rate_week = (last_weight - prev_weight) / prev_weight
            // 日成長率 = 周成長率 ÷ 7
            const grow_rate_day = Math.round(( grow_rate_week / 7 ) * 1000) / 1000
            return grow_rate_day
        },


        async getPools({state, commit, dispatch}, position_id){ // 取得池號資料
            try {
                const params = {
                    position_id: position_id,
                    // daily_report: true,
                    record_date: state.record_date
                }
                const response = await apiGetDailyReport(params)
                if( response.status === 200 ){
                    const datas = response['data']
                    for(const data of datas){
                        const schedule =  data['schedules']  ? data['schedules'] : null
                        data['days'] = null
                        if(!schedule) continue // 無排程就滾蛋
                        
                        data['days'] = schedule['days']
                        
        
                        let { weight_records, records, water_records } = schedule
                        /* 重量資料處理 */
                        weight_records = weight_records.reverse()
            
                        data['cur_weight'] = weight_records.reduce( 
                            (acc, cur, index)=> 
                                acc
                                + (cur['weight'] || '❌') 
                                +  (index < weight_records.length-1 ? ' / ' : ''), ''
                        )

                        data['weight_date'] = weight_records.length > 0 ? weight_records[0]['record_date'] : '❌' // 測重日期(最後一天)
                        data['grow_rate'] = await dispatch('caculateGrowRate', weight_records)
        
                        /* 餵食資料處理 */
                        records = records.reverse()
                        // data['day'] = records.length > 0 ? records[0]['day'] : '❌' // 養殖天數(最後一天)
                        data['real_feed'] = records.length > 0 ? records[0]['real_feed'] : '❌' // 餵食量(最後一天)
                        data['remark'] = records.length > 0 ? records[0]['remark'] : '❌' // 餵食備註(最後一天)
                        data['category'] = records.length > 0 ? records[0]['category'] : '❌' // 飼料別(最後一天)

                        
                        /* 排程處理 */
                        data['start_date'] = schedule['start_date'] // 入苗日期
                        data['is_active'] = schedule['is_active'] // 是否運行
                        data['empty_status'] = schedule['empty_status'] // 空池狀態
                        data['empty_status_display'] = schedule['empty_status_display'] // 空池狀態(名稱)
                        data['amount'] = schedule['amount'] // 放苗尾數
                        data['seed_id'] = schedule['seed_id'] === '1' ? '未知' : schedule['seed_id']// 苗源ID
                        data['age'] = schedule['age'] // 苗齡
                        data['is_empty'] = schedule['is_empty'] // 是否空池
                        data['empty_date'] = schedule['empty_date'] // 空池日期
                        data['empty_days'] = schedule['empty_days'] // 空池天數
                        data['status'] = schedule['is_new_pool'] ? 'new' : schedule['status'] // 排程狀態
                        data['empty_records'] = schedule['empty_records'] // 排程狀態
                        data['density'] =schedule['density'] // 排程密度

        
                        /* 水質資料處理 */
                        water_records = water_records.reverse()
                        data['water_date'] = water_records.length > 0 ? water_records[0]['record_date'] : '❌' // 測水日期(最後一天)
                        data['cur_pH'] = water_records.reduce( (acc, cur, index)=> acc + (cur['pH'] || '❌') +  (index < water_records.length-1 ? ' / ' : ''), '' )
                        data['cur_NH'] = water_records.reduce( (acc, cur, index)=> acc + (cur['NH'] || '❌') +  (index < water_records.length-1 ? ' / ' : ''), '' )
                        data['cur_HNO2'] = water_records.reduce( (acc, cur, index)=> acc + (cur['HNO2'] || '❌') +  (index < water_records.length-1 ? ' / ' : ''), '' )
                        data['cur_Salinity'] = water_records.reduce( (acc, cur, index)=> acc + (cur['salinity'] || '❌') +  (index < water_records.length-1 ? ' / ' : ''), '' )
                        data['cur_Temp'] = water_records.reduce( (acc, cur, index)=> acc + (cur['temp'] || '❌') +  (index < water_records.length-1 ? ' / ' : ''), '' )
                        data['cur_DO'] = water_records.reduce( (acc, cur, index)=> acc + (cur['DO'] || '❌') +  (index < water_records.length-1 ? ' / ' : ''), '' )
                    }
                    return datas
                    
                }
            }catch(err){
                console.log(`讀取養殖池資料時發生錯誤: ${err}`);
                return []
            }
        }


    },

}