import { loadView } from "@/router/loadView"

export default [
    { /* 排程調控結果 */
        path: '/transfer-schedule',
        name: 'TransferScheduleView',
        component: loadView("TransferSell/HistoryTransferManagement/TransferScheduleView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 出貨調控紀錄 */
        path: '/transfer-sell',
        name: 'TransferSellView',
        component: loadView("TransferSell/HistoryTransferManagement/TransferSellView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },


    { /* 儀錶板 */
        path: '/transfer/dashboard',
        name: 'TransferDashboardView',
        component: loadView("TransferSell/HistoryTransferManagement/TransferDashboardView"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 地區 */
        path: '/transfer/dashboard/area/:id',
        name: 'TransferDashboardAreaDetail',
        component: loadView("TransferSell/HistoryTransferManagement/Dashboard/TransferDashboardAreaDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 公司 */
        path: '/transfer/dashboard/company/:id',
        name: 'TransferDashboardCompanyDetail',
        component: loadView("TransferSell/HistoryTransferManagement/Dashboard/TransferDashboardCompanyDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },

    { /* 儀錶板 - 迴路 */
        path: '/transfer/dashboard/circuit/:id',
        name: 'TransferDashboardCircuitDetail',
        component: loadView("TransferSell/HistoryTransferManagement/Dashboard/TransferDashboardCircuitDetail"),
        meta: { roles: ['data_controller'] }  // 需要為 data_controller
    },


];