// /* 蝦苗生產紀錄 */
// import axios from 'axios'
// const instance = axios.create({
//     baseURL: '/api/stations/areas/'
// })

  
import service from '@/api/service'
const url = "/stations/water/position"

  export const getStationWater = (params) => service.get(`${url}/`, {params})

  export const getStationWaterHistory = (id, params) => service.get(`${url}/history/${id}/`, {params})
