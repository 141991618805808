// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class TransferSellService extends BaseService{
    constructor(){
        super('transfer-sell') // 帶入 order為 resource
    }

    // 得到該排程轉移後的結果
    get_after_transfer_schedule_sell(id, params) {
        return service.get(`/${this.resource}/after-transfer/schedule/${id}/`, { params });
    }
}


// 將封裝的 class匯出
export default new TransferSellService()