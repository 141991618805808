import service from '@/api/service'
const url = '/food/upload/excel/color/v2' // /api/temp
// 上傳Excel
export const uploadColorExcel_v2 = (data) => service.post(`${url}/`, data, {
    headers: {
    'Content-Type': 'multipart/form-data',
    },
})

// 進行匯入
export const importColorExcel_v2 = (data, config) => service.post(`${url}/import/`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
        ...config
    })


// 下拉查看加工品檢驗是否已經匯入
export const searchImportColorExcel = (data, config) => service.post(`${url}/import/search/`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
        ...config
    })

