import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (芽廠列表)
    { /* 芽廠列表 */
        path: '/sprout-list',
        name: 'SporutList',
        component: loadView("SeedManagement/SproutView/SproutList"),
        meta: { roles: ['admin', 'manager'] }, // 需要為 Admin或 Manager
        redirect: {
            name: 'SproutView',
            params: {  // 預設 ID為 0
                id: 1,
            }
        },
        children: [
            {
                path: 'view/:id',
                name: 'SproutView',
                component: loadView("SeedManagement/SproutView/SproutView"),
                props: true,
                meta: { roles: ['admin', 'manager'] }
            },
        ],
    },


    { /* 創建芽排程 */
        path: '/sprout-list/create-sprout-schedule/:id',
        name: 'CreateSproutSchedule',
        component: loadView("SeedManagement/SproutView/CreateSproutSchedule"),
        props: true,
        meta: {
            roles: ['admin', 'manager']
        },
    },

    { /* 芽排程列表 */
        path: '/sprout-list/sprout-schedule-view/:id',
        name: 'SproutScheduleView',
        component: loadView("SeedManagement/SproutSchedule/SproutScheduleView"),
        props: true, // 允許傳遞資料
        meta: { roles: ['admin', 'manager'] },
        redirect: { name: 'SeedScheduleList' }, // 直接導引到這列表頁面
        children: [ // 子路由(出苗列表)
            {
                path: 'views',
                name: 'SeedScheduleList',
                component: loadView("SeedManagement/SproutSchedule/SeedScheduleList"),
                props: true
            },
        ],
    },

    { /* 創建出苗排程 */
        path: '/sprout-list/sprout-schedule-view/:id/create',
        name: 'CreateSeedSchedule',
        component: loadView("SeedManagement/SproutSchedule/CreateSeedSchedule"),
        props: true, // 允許傳遞資料
        meta: { roles: ['admin', 'manager'] },
    },

    { /* 創建病毒檢驗 */
        path: '/sprout-list/sprout-schedule-view/:id/virus',
        name: 'CreateVirusRecord',
        component: loadView("SeedManagement/SproutSchedule/CreateVirusRecord"),
        props: true, // 允許傳遞資料
        meta: { roles: ['admin', 'manager'] },
    },


    { /* 出苗排程View */
        path: '/sprout-list/sprout-schedule-view/:sprout_sc_id/seed-schedule/:id/views',
        name: 'SeedScheduleView',
        component: loadView("SeedManagement/Seed/SeedScheduleView"),
        props: true, // 允許傳遞資料
        meta: { roles: ['admin', 'manager'] },
    },



    //#endregion

    //#region 【2】 (蝦苗管理)
    {
        path: '/seed-management',
        name: 'SeedManagement',
        component: loadView("SeedManagement/SeedManagement"),
        props: true, // 允許傳遞資料
        meta: { roles: ['admin', 'manager'] },
    },
    //#endregion

];