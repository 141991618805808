// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class FoodRCW360TempProbeDataService extends BaseService{
    constructor(){
        super('food/rcw360_temp/probe-data') // 帶入 order為 resource
    }


}

// 將封裝的 class匯出
export default new FoodRCW360TempProbeDataService()