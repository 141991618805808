// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class ScheduleService extends BaseService{
    constructor(){
        super('schedule') // 帶入 order為 resource
    }
    // 新增自定義方法：根據日期獲取記錄
    get_start_dates(params) {
        return service.get(`/${this.resource}/dates/start/`, { params });
    }
    get_empty_dates(params) {
        return service.get(`/${this.resource}/dates/empty/`, { params });
    }


    batch_update(data){
        return service.put(`/${this.resource}/batch/update/`, data)
    }
}


// 將封裝的 class匯出
export default new ScheduleService()