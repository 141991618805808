import { loadView } from "@/router/loadView"
export default [
    


    //#endregion

    //#region 【4】 (成長分析)
    { /* 預期成長曲線 */
        path: '/others/grow-analysis-predict',
        component: loadView("Others/GrowAnalysis/GrowAnalysisPredict"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
   
    //#endregion

    //#region 【5】 (天氣資訊)
    {
        path: '/weather',
        component: loadView("Others/Weather"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },

    //#endregion

    //#region 【6】 (UI測試區)
    {
        path: '/others/ui-test',
        component: loadView("Others/UI/UITest"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    //#endregion

    //#region 【7】 (場域地圖 - 各場池分布)
    { /* 池分布 */
        path: '/station-map-test',
        name: 'StationMapTest',
        component: loadView("Others/StationMapTest/Test"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },

    { /* 特定池相關資料 */
        path: '/station-map-test/:name',
        name: 'StationMapTestView',
        component: loadView("Others/StationMapTest/TestView"),
        meta: { roles: ['admin'] } // 需要為 Admin 
    },
    //#endregion

    //#region 【8】 (運蝦車)
    { /* 運蝦車及製程溫度監控 */
        path: '/delivery',
        name: 'Delivery',
        component: loadView("Others/Delivery/Delivery"),
        meta: { roles: ['admin'] }  // 需要為 Admin
    },
    //#endregion

];