import { loadView } from "@/router/loadView"
export default [
    //#region 【1】 (外部)
    { // 水文圖表測試(外部)
        path: '/hydrology/external/view',
        name: 'HydrologyExternalView',
        component: loadView("HydrologyManagement/HydrologyExternalView"),
        meta: { roles: ['admin'] } // 需要為 Admin
      },
    //#endregion

    //#region 【1】 (內部)
    { // 水文圖表測試(內部)
        path: '/hydrology/internal/view',
        name: 'HydrologyInternalView',
        component: loadView("HydrologyManagement/HydrologyInternalView"),
        meta: { roles: ['admin'] } // 需要為 Admin
      },
    //#endregion

    //#region 【1】 (內部)
    { // 潮位站資訊
        path: '/hydrology/external/tide/station',
        name: 'HydrologyExternalTideStationView',
        component: loadView("HydrologyManagement/HydrologyExternalTideStationView"),
        meta: { roles: ['admin'] },  // 需要為 Admin
    },
     
      { // 潮汐資料(內部)
        path: '/hydrology/external/tide/area',
        name: 'HydrologyExternalTideAreaView',
        component: loadView("HydrologyManagement/HydrologyExternalTideAreaView"),
        meta: { roles: ['admin'] },  // 需要為 Admin
        redirect: { 
            name: 'TideAreaThreeDaysView',
            params: {
                id: 'T001003',
            },
        },
        children: [
            { // 未來三天
                path: ':id/future-three-days',
                name: 'TideAreaThreeDaysView',
                component: loadView("HydrologyManagement/TideAreaView/TideAreaThreeDaysView"),
                props: true,
                meta: { roles: ['admin']}, // 需要為 Admin或 Manager
            },
            { // 未來七天
                path: ':id/future-seven-days',
                name: 'TideAreaSevenDaysView',
                component: loadView("HydrologyManagement/TideAreaView/TideAreaSevenDaysView"),
                props: true,
                meta: { roles: ['admin']}, // 需要為 Admin或 Manager
            },
            { // 歷史資料
                path: ':id/history',
                name: 'TideAreaHistoryView',
                component: loadView("HydrologyManagement/TideAreaView/TideAreaHistoryView"),
                props: true,
                meta: { roles: ['admin']}, // 需要為 Admin或 Manager
            },
            
        ],
    },
    //#endregion


      //#region 【1】 (混合)
      { // 水文圖表測試(內部)
        path: '/hydrology/mixed/view',
        name: 'HydrologyMixedView',
        component: loadView("HydrologyManagement/HydrologyMixedView"),
        meta: { roles: ['admin'] } // 需要為 Admin
      },
    //#endregion


    //#region 【1】 (水質檢驗)
    {
        path: '/hydrology/water-quality',
        name: 'WaterQuality',
        component: loadView("HydrologyManagement/WaterQuality"),
        meta: {roles: ['admin']},
        redirect: { 
            name: 'WaterQualityView',
            params: {
                id: 'yushan',
            },
        },
        children: [
            {
                path: 'view/:id',
                name: 'WaterQualityView',
                component: loadView("HydrologyManagement/WaterQuality/WaterQualityView"),
                props: true,
                meta: { roles: ['admin']}, // 需要為 Admin或 Manager
            },
        ]
    },

];