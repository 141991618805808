import { loadView } from "@/router/loadView"

export default [

    { 
        path: '/water-record-for-seed',
        name: 'WaterRecordForSeed',
        component: loadView("WaterRecodForSeed/WaterRecordForSeedView"), // 非同步載入: 有需要時才載入元件(lazy-loading)
        redirect: '/water-record-for-seed/position',
        children: [
            { 
                path: 'position',
                name: 'WaterRecordForPositionView',
                component: loadView("WaterRecodForSeed/Position/WaterRecordForPositionView"), // 非同步載入: 有需要時才載入元件(lazy-loading)
            },
            { 
                path: 'pool',
                name: 'WaterRecordForPoolView',
                component: loadView("WaterRecodForSeed/Pool/WaterRecordForPoolView"), // 非同步載入: 有需要時才載入元件(lazy-loading)
            },
        ]
    },


    { 
        path: '/water-record-for-seed/position/create',
        name: 'CreateWaterRecordForPosition',
        component: loadView("WaterRecodForSeed/Position/CreateWaterRecordForPosition"), // 非同步載入: 有需要時才載入元件(lazy-loading)
    },

    { 
        path: '/water-record-for-seed/position/update/:id',
        name: 'UpdateWaterRecordForPosition',
        component: loadView("WaterRecodForSeed/Position/UpdateWaterRecordForPosition"), // 非同步載入: 有需要時才載入元件(lazy-loading)
    },

    { 
        path: '/water-record-for-seed/pool/create',
        name: 'CreateWaterRecordForPool',
        component: loadView("WaterRecodForSeed/Pool/CreateWaterRecordForPool"), // 非同步載入: 有需要時才載入元件(lazy-loading)
    },

    { 
        path: '/water-record-for-seed/pool/update/:id',
        name: 'UpdateWaterRecordForPool',
        component: loadView("WaterRecodForSeed/Pool/UpdateWaterRecordForPool"), // 非同步載入: 有需要時才載入元件(lazy-loading)
    },
    
]