// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class WaterRecordService extends BaseService{
    constructor(){
        super('breeding/records/water-record') // 帶入 order為 resource
    }

    get_dates(params){
        return service.get(`${this.resource}/dates/`, {params})
    }
 
}


// 將封裝的 class匯出
export default new WaterRecordService()