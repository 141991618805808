import { apiGetExternalProductSchedule  } from '@/api/'
import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {
        schedules: [],
    }
}



export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setSchedules(state, payload){
            state.schedules = payload
        },

        createSchedule(state, created_data){
            state.schedules.push(created_data)
        },
        updateSchedule(state, updated_data){
            const {id} = updated_data
            const index = state.schedules.findIndex( (schedule)=>schedule['id'] === id )
            state.schedules.splice(index, 1, updated_data)
        },

        createInspect(state, payload){
            const {category, created_data} = payload
            const {product_sc_id} = created_data
            const schedule = state.schedules.find( (schedule)=>schedule['id'] === product_sc_id )
            
            if( category === 'sweet'){
                schedule['sweets'].push(created_data)
            }
            else if( category === 'color'){
                schedule['colors'].push(created_data)
            }
            else if( category === 'fullness'){
                schedule['fullnesses'].push(created_data)
            }
            else if( category === 'ice'){
                schedule['ices'].push(created_data)
            }
        },
        updateInspect(state, payload){
            const {category, updated_data} = payload
            const {id, product_sc_id} = updated_data
            const schedule = state.schedules.find( (schedule)=>schedule['id'] === product_sc_id )
            
            if( category === 'sweet'){
                const index = schedule['sweets'].findIndex( item => item['id'] === id )
                schedule['sweets'].splice(index, 1, updated_data)
            }
            else if( category === 'color'){
                const index = schedule['colors'].findIndex( item => item['id'] === id )
                schedule['colors'].splice(index, 1, updated_data)
            }
            else if( category === 'fullness'){
                const index = schedule['fullnesses'].findIndex( item => item['id'] === id )
                schedule['fullnesses'].splice(index, 1, updated_data)
            }
            else if( category === 'ice'){
                const index = schedule['ices'].findIndex( item => item['id'] === id )
                schedule['ices'].splice(index, 1, updated_data)
            }
        },

        deleteInspect(state, payload){
            const {category, deleted_id, product_sc_id} = payload
            const schedule = state.schedules.find( (schedule)=>schedule['id'] === product_sc_id )
            
            if( category === 'sweet'){
                schedule['sweets'] = schedule['sweets'].filter( item => item['id'] !== deleted_id )
            }
            else if( category === 'color'){
                schedule['colors'] = schedule['colors'].filter( item => item['id'] !== deleted_id )
            }
            else if( category === 'fullness'){
                schedule['fullnesses'] = schedule['fullnesses'].filter( item => item['id'] !== deleted_id )
            }
            else if( category === 'ice'){
                schedule['ices'] = schedule['ices'].filter( item => item['id'] !== deleted_id )
            }
        }
    
    },
    
    actions: { 

    },

}