// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"

class M10PanelDevicesService extends BaseService{
    constructor(){
        super('m10-panel/devices') // 帶入 order為 resource
    }
}


// 將封裝的 class匯出
export default new M10PanelDevicesService()