
import service from '@/api/service'
const url = "/food/upload/image/taste"
  
// 上傳檢查
export const uploadFoodTasteImage = (data) => service.post(`${url}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
// 匯入系統
export const importFoodTasteImage = (data) => service.post(`${url}/import/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})

// 匯入系統
export const searchImportFoodTasteImage = (data) => service.post(`${url}/import/search/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
