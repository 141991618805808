// /* 蝦苗生產紀錄 */
// import axios from 'axios'
// const instance = axios.create({
//     baseURL: '/api/stations/areas/'
// })

  
import service from '@/api/service'
const url = "/stations/areas"

  export const getStationArea = (params) => service.get(`${url}/`, {params})
  export const createStationArea = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleStationArea = (id) => service.get(`${url}/${id}/`)
  export const updateStationArea = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })
  export const deleteStationArea = (id) => service.delete(`${url}/${id}/`)

