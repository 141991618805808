// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class VirusScheduleService extends BaseService{
    constructor(){
        super('virus/schedule') // 帶入 order為 resource
    }
    // 得到日期列表
    getDates(params) {
        return service.get(`/${this.resource}/dates/`, { params });
    }
    

}

// 將封裝的 class匯出
export default new VirusScheduleService()