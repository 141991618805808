// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class ScheduleAnaylsisService extends BaseService{
    constructor(){
        super('analysis') // 帶入 order為 resource
    }

    get_history(params) {
        return service.get(`/${this.resource}/history/`, { params });
    }

    get_bonus(params) {
        return service.get(`/${this.resource}/bonus/`, { params });
    }

    get_harvest(params) {
        return service.get(`/${this.resource}/harvest/`, { params });
    }

    get_grow(params) {
        return service.get(`/${this.resource}/grow/`, { params });
    }
    // 取得預測獎金
    get_predict_bonus_by_schedule_id(id, params) {
        return service.get(`/${this.resource}/bonus/${id}/get-predict/`, { params });
    }

    // 得到該排程輸入檢查資訊
    get_alert_input(params) {
        return service.get(`/${this.resource}/alert-input/`, { params });
    }
}


// 將封裝的 class匯出
export default new ScheduleAnaylsisService()