// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class TransferFeedScheduleService extends BaseService{
    constructor(){
        super('transfer-feed-schedule') // 帶入 order為 resource
    }

    // 取得單一排程
    fetch_schedule(params) {
        return service.get(`/${this.resource}/fetch-schedule/`, { params });
    }
    
    update_schedule_for_transfer(id, data) {
        return service.put(`/${this.resource}/fetch-schedule/update/${id}/`, data);
    }

   
}


// 將封裝的 class匯出
export default new TransferFeedScheduleService()