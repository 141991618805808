import { ProductProcessedCategoryService } from "@/api/services"
import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {
        processed_product_category_chip_style: {},
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        // 查看是否有 processed_product_category_chip_style
        isIncludeProcessedProductCategoryChipStyle: (state) => {
            return Object.keys(state.processed_product_category_chip_style).length > 0
        },
     
    },
    
    mutations: { 
        setProcessedProductCategoryChipStyle(state, payload){
            state.processed_product_category_chip_style = payload
        },
    },
    
    actions: { 
        //#region (呼叫API)
        async fetchProcessedProductCategories({state, commit}, payload){
            if (state.is_fetched) return; // 如果已加載過，直接返回
            try{
                const response = await ProductProcessedCategoryService.getAll()
                if(response.status === 200){
                    const response_data = response['data']
                    const map = {}
                    for(const item of response_data){
                        map[item.id] = item
                    }
                    commit("setProcessedProductCategoryChipStyle", map)
                }
            }catch(err){
                console.log(`發生問題: ${err}`);
            }
        },

   
    },

}