import service from '@/api/service'
const url = "/expected-schedule"
  
  export const getExpectedSchedule = (params) => service.get(`${url}/`, {params})
  export const createExpectedSchedule = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleExpectedSchedule = (id) => service.get(`${url}/${id}/`)
  export const updateExpectedSchedule = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { 'Content-Type': 'application/json' },
  })
  export const deleteExpectedSchedule = (id) => service.delete(`${url}/${id}/`)