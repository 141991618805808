import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {
        position_name_list: [],

        //#region (甜度)
        sweets: [], // 甜度資料
        sweet_positions: [], // 目前甜度資料有包含的場列表
        sweets_table_checked: [], // 甜度(表格勾選)
        sweets_chart_selected: [], // 甜度(圖表選擇 - 養殖場)
        //#endregion

        //#region (蝦色)
        colors: [], // 蝦色資料
        color_positions: [], // 目前蝦色資料有包含的場列表
        colors_table_checked: [], // 蝦色(表格勾選)
        colors_chart_selected: [], // 蝦色(圖表選擇 - 養殖場)
        //#endregion

        //#region (肥滿度)
        fullnesses: [], // 肥滿度資料
        fullness_positions: [], // 目前肥滿度資料有包含的場列表
        fullnesses_table_checked: [], // 肥滿度(表格勾選)
        fullnesses_chart_selected: [], // 肥滿度(圖表選擇 - 養殖場)
        //#endregion

        //#region (冷凍蝦)
        ices: [], // 冷凍蝦資料
        ice_positions: [], // 目前冷凍蝦資料有包含的場列表
        ices_table_checked: [], // 冷凍蝦(表格勾選)
        ices_chart_selected: [], // 冷凍蝦(圖表選擇 - 養殖場)
        //#endregion
    }
}



export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setPositionNameList(state, payload){ // 將場列表
            state.position_name_list = payload
        },

        //#region (甜度處理)
        setSweets(state, payload){ // 將甜度資料匯入
            state.sweets = payload
            
            const position_names = state.sweets.flatMap( item => Object.keys(item['join_pools']) )
            // 透過 FoodStuff.vue 定義好的完整場列表，來去過濾出我們需要的場
            state.sweet_positions = state.position_name_list.filter( name => position_names.includes(name) )
        },
        setSweetTableChecked(state, payload){ // 表格勾選(IDs)
            state.sweets_table_checked = payload
        },
        setSweetChartSelected(state, payload){ // 圖表選擇(養殖場列表)
            state.sweets_chart_selected = payload
        },
        //#endregion

        //#region (蝦色處理)
        setColors(state, payload){ // 將蝦色資料匯入
            state.colors = payload
            
            const position_names = state.colors.flatMap( item => Object.keys(item['join_pools']) )
            // 透過 FoodStuff.vue 定義好的完整場列表，來去過濾出我們需要的場
            state.color_positions = state.position_name_list.filter( name => position_names.includes(name) )
        },
        setColorTableChecked(state, payload){ // 表格勾選(IDs)
            state.colors_table_checked = payload
        },
        setColorChartSelected(state, payload){ // 圖表選擇(養殖場列表)
            state.colors_chart_selected = payload
        },
        //#endregion
        
        //#region (肥滿度處理)
        setFullnesses(state, payload){ // 將肥滿度資料匯入
            state.fullnesses = payload
            
            const position_names = state.fullnesses.flatMap( item => Object.keys(item['join_pools']) )
            // 透過 FoodStuff.vue 定義好的完整場列表，來去過濾出我們需要的場
            state.fullness_positions = state.position_name_list.filter( name => position_names.includes(name) )
        },
        setFullnessTableChecked(state, payload){ // 表格勾選(IDs)
            state.fullnesses_table_checked = payload
        },
        setFullnessChartSelected(state, payload){ // 圖表選擇(養殖場列表)
            state.fullnesses_chart_selected = payload
        },
        //#endregion
        
        //#region (冷凍蝦處理)
        setIces(state, payload){ // 將冷凍蝦資料匯入
            state.ices = payload
            
            const position_names = state.ices.flatMap( item => Object.keys(item['join_pools']) )
            // 透過 FoodStuff.vue 定義好的完整場列表，來去過濾出我們需要的場
            state.ice_positions = state.position_name_list.filter( name => position_names.includes(name) )
        },
        setIceTableChecked(state, payload){ // 表格勾選(IDs)
            state.ices_table_checked = payload
        },
        setIceChartSelected(state, payload){ // 圖表選擇(養殖場列表)
            state.ices_chart_selected = payload
        },
        //#endregion
        
       
    },
    
    actions: {
        //#region (甜度)
        createSweet({state, commit}, created_data){ // 刪除甜度資料(依照ID)
            let sweets = []
            if(Array.isArray( created_data )){ // 他是陣列
                sweets = [...state.sweets, ...created_data]
            }else if(typeof created_data === 'object'){ // 他是Object
                sweets = [...state.sweets]
                sweets.unshift(created_data)
            }else{
                console.log("填入甜度時不符合規則");
                return
            }
            commit("setSweets", sweets.sort((a,b) => new Date(b) - new Date(a)))
        },
        updateSweet({state, commit}, updated_data){ // 刪除甜度資料(依照ID)
            const {id} = updated_data
            const sweets = [...state.sweets]
            const index = sweets.findIndex( item => item['id'] === id )
            sweets.splice(index, 1, updated_data)
            commit("setSweets", sweets)
        },
        deleteSweet({state, commit}, id){ // 刪除甜度資料(依照ID)
            const after_deleted_sweets = state.sweets.filter( item => item['id'] !== id )
            commit("setSweets", after_deleted_sweets)
        },
        //#endregion

        //#region (蝦色)
        createColor({state, commit}, created_data){ // 刪除蝦色資料(依照ID)
            let colors = []
            if(Array.isArray( created_data )){ // 他是陣列
                colors = [...state.colors, ...created_data]
            }else if(typeof created_data === 'object'){ // 他是Object
                colors = [...state.colors]
                colors.unshift(created_data)
            }else{
                console.log("填入蝦色時不符合規則");
                return
            }
            commit("setColors", colors.sort((a,b) => new Date(b) - new Date(a)))
        },
        updateColor({state, commit}, updated_data){ // 刪除蝦色資料(依照ID)
            const {id} = updated_data
            const colors = [...state.colors]
            const index = colors.findIndex( item => item['id'] === id )
            colors.splice(index, 1, updated_data)
            commit("setColors", colors)
        },
        deleteColor({state, commit}, id){ // 刪除蝦色資料(依照ID)
            const after_deleted_colors = state.colors.filter( item => item['id'] !== id )
            commit("setColors", after_deleted_colors)
        },
        //#endregion

        //#region (肥滿度)
        createFullness({state, commit}, created_data){ // 刪除肥滿度資料(依照ID)
            let fullnesses = []
            if(Array.isArray( created_data )){ // 他是陣列
                fullnesses = [...state.fullnesses, ...created_data]
            }else if(typeof created_data === 'object'){ // 他是Object
                fullnesses = [...state.fullnesses]
                fullnesses.unshift(created_data)
            }else{
                console.log("填入肥滿度時不符合規則");
                return
            }
            commit("setFullnesses", fullnesses.sort((a,b) => new Date(b) - new Date(a)))
        },
        updateFullness({state, commit}, updated_data){ // 刪除肥滿度資料(依照ID)
            const {id} = updated_data
            const fullnesses = [...state.fullnesses]
            const index = fullnesses.findIndex( item => item['id'] === id )
            fullnesses.splice(index, 1, updated_data)
            commit("setFullnesses", fullnesses)
        },
        deleteFullness({state, commit}, id){ // 刪除肥滿度資料(依照ID)
            const after_deleted_fullnesses = state.fullnesses.filter( item => item['id'] !== id )
            commit("setFullnesses", after_deleted_fullnesses)
        },
        //#endregion

        //#region (冷凍庫)
        createIce({state, commit}, created_data){ // 刪除冷凍庫資料(依照ID)
            let ices = []
            if(Array.isArray( created_data )){ // 他是陣列
                ices = [...state.ices, ...created_data]
            }else if(typeof created_data === 'object'){ // 他是Object
                ices = [...state.ices]
                ices.unshift(created_data)
            }else{
                console.log("填入冷凍庫時不符合規則");
                return
            }
            commit("setIces", ices.sort((a,b) => new Date(b) - new Date(a)))
        },
        updateIce({state, commit}, updated_data){ // 刪除冷凍庫資料(依照ID)
            const {id} = updated_data
            const ices = [...state.ices]
            const index = ices.findIndex( item => item['id'] === id )
            ices.splice(index, 1, updated_data)
            commit("setIces", ices)
        },
        deleteIce({state, commit}, id){ // 刪除冷凍庫資料(依照ID)
            const after_deleted_ices = state.ices.filter( item => item['id'] !== id )
            commit("setIces", after_deleted_ices)
        },
        //#endregion

    },

}