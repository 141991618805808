<template>
  <v-app-bar app :color="darkMode ? '#333' : 'white'">
        <!-- 三條線 -->
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')"></v-app-bar-nav-icon>

        <!-- 標題 -->
        <v-toolbar-title class="logo-title font-weight-bold d-flex align-center">
            <img class="mr-2" width="35" height="35" 
                :src="require(`@/assets/images/layouts/appbar/shrimp.svg`)" alt=""
            >
            
            <!-- 白蝦養殖系統 -->
            {{ title }}
        </v-toolbar-title>

        <!-- 換行 -->
        <v-spacer></v-spacer>

        <!-- 右側按鈕區 -->
        <div class="d-flex align-center" >

            <!-- (限定已經登入)使用者菜單 -->
            <v-menu v-if="user_info"
                v-model="user_menu" style="z-index: 10;"
                bottom :offset-y="true" right >
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" 
                        class="d-flex align-center">
                        <div :class="user_menu ? 'primary--text' : 'grey--text text--darken-2'"
                            class="gap-1 font-weight-bold d-flex align-center">
                            <!-- 使用者ICON -->
                            <v-icon :color="user_menu ? 'primary' : 'grey darken-2'">
                                mdi-account-circle
                            </v-icon>
                            <!-- 使用者名稱 -->
                            {{ user_info['name'] }}
                        </div>

                        <!-- 菜單 -->
                        <v-icon :color="user_menu ? 'primary' : 'grey darken-2'">
                            {{ user_menu ? 'mdi-menu-up' : 'mdi-menu-down'}}
                        </v-icon>
                    </div>
                </template>


                <v-list-item-group color="primary" class="elevation-4">
                    <!-- 使用者 -->
                    <v-list-item 
                        v-for="(item, i) in items" :key="i"
                        class="white" link
                        :value="route_name === item['name']"
                        :to="{name: item['name']}">
                        <v-list-item-icon>
                            <v-icon :color="route_name === item['name'] ? 'primary' : ''">
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                            
                        <v-list-item-title :class="route_name === item['name'] ? 'font-weight-bold' : ''">
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-divider color="white"></v-divider>

                    <!-- 登出 -->
                     <v-list-item @click="logout" 
                        class="white">
                        <v-list-item-icon>
                            <v-icon class="menu-icon">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>登出</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>

       
            </v-menu>

            <!-- 尚未登入 -->
            <div v-else>
                <!-- 使用者ICON -->
                <v-icon color="grey darken-2">
                    mdi-account-circle
                </v-icon>
                <span class="font-weight-bold grey--text text--darken-2">
                    未登入
                </span>
            </div>
            
        </div>

    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Appbar',

    data(){
        return {
            title: process.env.VUE_APP_TITLE,
            darkMode: false, // sunshine 、 night
            user_menu: false, 
            items: [
                {name: 'UserRegister', title: '開通用戶', icon: 'mdi-account-plus-outline'},
                {name: 'UserProfile', title: '使用者設定', icon: 'mdi-card-account-details'},
                // {title: '登出', },
            ]
        }
    },

    mounted(){
    },

    computed:{
        ...mapState('auth', {
            user_info: state => state.user_info
        }),

        route_name(){
            return this.$route.name
        }
    },

    methods: {
        goToUserRegistry(){
            this.$router.push({
                name: 'UserRegister'
            })
        },

        async logout(){ // 登出
            const swal = await this.$swal.fire({
                title: '登出提醒',
                text: '確定要登出了嗎?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '確定',
                cancelButtonText: '取消',
            })

            if(swal.isConfirmed){
                localStorage.clear()
                this.$store.commit("auth/delToken")
                this.$store.commit("auth/delUser")
                this.$router.push({ 
                    name: 'LoginView'
                })

                this.$swal.fire("登出成功!", "", "success")
            }
        },
    }
}
</script>

<style>

</style>