import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (目標統計表)
    { /* 儀錶板 */
        path: '/team/dashboard/',
        name: 'TeamDashboard',
        component: loadView("TeamManagement/TeamDashboard"),
        meta: { roles: ['admin', 'manager', 'team_leader'] }, // 需要為 Admin
        redirect: {
            name: 'TeamDashboardView',
            params: {  // 如果沒ID，則為1
                id: sessionStorage.getItem("team_id") || 1
            }
        },
        children: [
            { path: 'view/:id', name: 'TeamDashboardView', 
                meta: { roles: ['admin', 'manager', 'team_leader'] }, 
                component: loadView("TeamManagement/Dashboard/TeamDashboardView"),
            },
        ],
    },


    { /* 案場目標 */
        path: '/team/dashboard/:id/position-console/:position_id',
        name: 'TeamPositionConsole',
        component: loadView("TeamManagement/PositionConsole/TeamPositionConsole"),
        meta: { roles: ['admin', 'manager', 'team_leader'] } // 需要為 Admin
    },

    //#endregion

];