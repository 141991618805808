import { ExternalProductService } from "@/api/services"
import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {
        // 外部生產資料列表
        products: [], 

        // 於 Sidebar中已經勾選的 product
        product_selected: [],

        // 和光蝦檢驗 換算平均的日期範圍
        range_date_idx: [
            dayjs().subtract(1, 'month').format("YYYY-MM-DD"), 
            dayjs().format("YYYY-MM-DD")
        ],

        
        
        regions: [],
        region_selected: [],
     
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setRangeDateIdx(state, payload){
            state.range_date_idx = payload
        },

        setProduct(state, payload){
            state.products = payload
        },
        setProductSelected(state, payload){
            state.product_selected = payload || []
        },
        setPositionInspect(state, payload){
            state.position_inspect = payload
        },

        setRegion(state, payload){
            state.regions = payload
        },
        setRegionSelected(state, payload){
            state.region_selected = payload
        },
    },
    
    actions: { 
        //#region (呼叫API)
        async getProductSchedule({state, commit}, payload){
            try{
                const response = await ExternalProductService.getAll()
                if(response.status === 200){
                    const response_data = response['data']

                    // 第一次進入頁面 = 沒有任何 product時 => 預設全選
                    if(state.products.length === 0){
                        commit("setProductSelected", response_data)
                    }

                    // 將擷取到資料傳給 state
                    commit("setProduct", response_data)
                }
            }catch(err){
                console.log(`發生問題: ${err}`);
            }
        },

        // 得到一個時間區間中檢驗數據平均
        async getRegionAvgInspectInRange({state, commit, dispatch}, payload='sweet'){
            try{
                const params = new URLSearchParams({
                    inspect_mode: payload,
                    date_range_after: state.range_date_idx[0],
                    date_range_before: state.range_date_idx[1],
                })
                const response = await ExternalProductService.get_region_avg_inspect(params)
                if(response.status === 200){
                    const response_data = response['data']


                    // 將擷取到資料傳給 state
                    await commit("setRegion", response_data)

                    // 目前勾選了那些數據
                    const region_selected_id_list = state.region_selected.map(region => region['id'])
                    await dispatch("changeRegionSelected", region_selected_id_list)
                }
            }
            catch(err){
                console.log(`發生問題: ${err}`);
            }
        },
        //#endregion


        // 變更選擇分區
        changeRegionSelected({state, commit}, payload){
            // 這邊的payload為 ID LIST
            const selected = state.regions.filter(region => payload.includes(region['id']))
            commit('setRegionSelected', selected)
        },

    },

}