// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class BreedingManagementFeedDiaryService extends BaseService{
    constructor(){
        super('breeding_management/feed_diary') // 帶入 order為 resource
    }

    get_schedule(id, params){
        return service.get(`/${this.resource}/schedule/${id}/`, { params });
    }
}


// 將封裝的 class匯出
export default new BreedingManagementFeedDiaryService()