import dayjs from "dayjs"

const getDefaultState = () => { // state
    return {
        range_date_idx: [ // 生產日期範圍
            // 近一個月
            sessionStorage.getItem("food_taste_range_date_idx")
                ? JSON.parse(sessionStorage.getItem("food_taste_range_date_idx"))[0]
                : dayjs().subtract(1, 'month').format("YYYY-MM-DD"),
            sessionStorage.getItem("food_taste_range_date_idx")
                ? JSON.parse(sessionStorage.getItem("food_taste_range_date_idx"))[1]
                : dayjs().format("YYYY-MM-DD")
        ],
    }
}


export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        setRangeDateIdx(state, payload){ // 切換日期
            state.range_date_idx = payload
            
            // 將日期儲存至Session
            sessionStorage.setItem('food_taste_range_date_idx', JSON.stringify(payload))
        }
    },
    actions: { 
        
    },
}