<template>
  <v-footer color="primary lighten-1" padless>
    <v-row justify="center" no-gutters>
    
        <v-col cols="12" class="text-center">
            <template v-for="route in routes"  >
                <v-btn :key="route.link" color="white" text rounded class="my-2"
                    v-if="(isRoleAdmin && route['requiresAdmin']) || (isRoleManager && route['requiresManager']) || (!route['requiresAdmin'] && !route['requiresManager'])">
                    <router-link class="white--text" :to="route.link">{{ route.title }}</router-link>
                </v-btn>
            </template>
        </v-col>
    
      <v-col cols="12" class="blue lighten-5 text-center">
            <img class="mirdc" src="@/assets/images/layouts/footer/MIRDC.png" />
      </v-col>

      <v-col class="blue lighten-5 py-4 text-center " cols="12">
        <strong>{{ new Date().getFullYear() }} — UC 開發</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "Footer",
    data: () => ({
        routes: [
          { title: '養殖日誌', icon: 'history_edu', link: '/feed-diary' , group: false, requiresAdmin: true, requiresManager: true},
        { title: '養殖紀錄', icon: 'mdi-book-plus', link: '/feed-record' , group: false, requiresAdmin: false},
        { title: '苗源分析', icon: 'star_rate', link: '/shrimp-seed' , requiresAdmin: true, requiresManager: true},
        { title: '排程分析', icon: 'mdi-school', link: '/shrimp-schedule/view' , requiresAdmin: true, requiresManager: true},
        ],
    }),

    computed:{
        ...mapState('auth', {
            access: state => state.authTokens.access, 
            refresh: state => state.authTokens.refresh,
            name: state => state.user_info.name,
            role: state => state.user_info.role
        }),
        isRoleAdmin(){
            return this.role === 'admin'
        },
        isRoleManager(){
            return this.role === 'manager'
        }
    }
}
</script>

<style lang="scss" scoped>
    .mirdc{
        width: 25%;
    }
    
    @media screen and (max-width: 1067px) {
        .mirdc{
            width: 40%;
        }
    }

    @media screen and (max-width: 767px) {
        .mirdc{
            width: 45%;
        }
    }
    @media screen and (max-width: 577px) {
        .mirdc{
            width: 70%;
        }
    }
</style>