import store from '@/store/' // 引入 vuex

export default {
    inserted(el, bindings){ // inserted
      
      // 當前使用者角色
      const roles = store.state['auth']['user_info'] && 
                      store.state['auth']['user_info']['roles']
      
     // 取得帶入的值，通常都是陣列。EX: ['admin', 'manager'] → 代表只允許這兩個角色可以顯示
      const requiredRoles = bindings['value'] 
      if (requiredRoles && // 確定有給值
          requiredRoles instanceof Array && // 必須為 Array
          requiredRoles.length > 0) { // 長度也超過一
          
          // 當一個使用者有多個角色
          const hasRole = roles.some(role =>  requiredRoles.includes(role))

          // 如果沒有這個權限，就移除整個 HTML 節點
          if (!hasRole) {
            el.parentNode && el.parentNode.removeChild(el)
          }
        } 
        
        else {
          throw new Error('Error Data Type.')
        }
    }
    
}