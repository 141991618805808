// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class ExternalProductService extends BaseService{
    constructor(){
        super('product/external-product') // 帶入 order為 resource
    }

    // 得到各區域的平均檢驗數據
    get_region_avg_inspect(params) {
        return service.get(`/${this.resource}/regions/average-inspect/`, { params });
    }


}

// 將封裝的 class匯出
export default new ExternalProductService()