import service from '@/api/service'
import axios from 'axios'

const UserRequest = axios.create({
    baseURL: '/api/system/user/'
})

const url = "/system/user"

//#region (重設密碼 + 忘記密碼)
// 得到所有問題
export const apiGetSecurityQuestions = (params) => service.get(`${url}/questions/`, {params})

// 找問題
export const apiSearchQuestion = (data) => UserRequest.post(`/search-question/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
// 忘記密碼
export const apiForgetPassword = (data) => UserRequest.post(`/forget-password/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
// 重設密碼
export const apiResetPassword = (data) => UserRequest.post(`/reset-password/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
// 檢查密碼
export const apiCheckPassword = (data) => service.post(`${url}/check-password/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
//#endregion

//#region (處理目前登入的使用者)
export const apiGetCurrentUser = (params) => service.get(`${url}/current/`, {params})
export const apiUpdateCurrentUser = (data) => service.put(`${url}/current/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})

//#endregion

export const apiRegistryUser = (data) => service.post(`${url}/registry/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
})
