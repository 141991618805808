// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class SweetService extends BaseService{
    constructor(){
        super('food/inspect/sweet') // 帶入 order為 resource
    }

    // 得到案場列表
    get_positions(params) {
        return service.get(`/${this.resource}/positions/`, { params });
    }

    // 得到平均檢驗值
    get_total_average_inspect(params) {
        return service.get(`/${this.resource}/total/average-inspect/`, { params });
    }
}

// 將封裝的 class匯出
export default new SweetService()