// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';
class VirusService extends BaseService{
    constructor(){
        super('virus') // 帶入 order為 resource
    }
    // 得到病毒管理數據
    get_management(params) {
        return service.get(`/${this.resource}/management/`, { params });
    }
    
    // 得到養殖排程列表
    get_by_feed_schedule(params) {
        return service.get(`/${this.resource}/by/feed-schedule/`, { params });
    }
    
    // 得到養殖排程
    get_by_feed_schedule_id(id, params) {
        return service.get(`/${this.resource}/by/feed-schedule/${id}/`, { params });
    }
    
    
    // 得到苗源排程列表
    get_by_seed_schedule_id(id, params) {
        return service.get(`/${this.resource}/by/seed-schedule/${id}/`, { params });
    }
    

}

// 將封裝的 class匯出
export default new VirusService()