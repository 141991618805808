// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class HydrologyWaterQualitySampleService extends BaseService{
    constructor(){
        super('hydrology/water-quality/sample') 
    }

    // 依照案場得到資訊
    get_position(params) {
        return service.get(`/${this.resource}/position/`, { params });
    }
}


// 將封裝的 class匯出
export default new HydrologyWaterQualitySampleService()