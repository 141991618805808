import { apiGetSingleSproutSchedule  } from '@/api/'
import dayjs from 'dayjs'
const getDefaultState = () => { // state
    return {

        // sprout_schedule: {}, // 芽排成
        is_create_seed_schedule: false, // 有新增一筆全新的出苗排程(用於芽排程中更新出苗總數)
    }
}



export default {
    namespaced: true,
    state: getDefaultState(),
    
    mutations: { 
        setSproutSchedule(state, payload){
            state.is_create_seed_schedule = payload
        }
       
    },
    
    actions: { 
           

    },

}