// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class HydrologyInternalStationService extends BaseService{
    constructor(){
        super('hydrology/internal/station') 
    }

    // 新增自定義方法：根據日期獲取記錄
    getDates(id, params) {
        return service.get(`/${this.resource}/${id}/data/dates/`, { params });
    }
}


// 將封裝的 class匯出
export default new HydrologyInternalStationService()