
import service from '@/api/service'
const url = '/food-stuff' // /api/temp

// #region (上傳 + 匯入Excel)
export const uploadFullnessExcel = (data) => service.post(`${url}/fullness/upload/`, data, {
    headers: {'Content-Type': 'multipart/form-data',}
})
export const importFullnessExcel = (data, config) => service.post(`${url}/fullness/import/`, data, {
    headers: {'Content-Type': 'multipart/form-data',},
    ...config
})
// #endregion

